<template>
  <div class="ShareList">
    <ul class="listBox">
      <li class="item" v-for="(item, index) in listProduct" :key="index">
        <!-- logo -->
        <van-row type="flex" align="center" class="infoBox">
          <van-col class="infoBoxLeft">
            <figure class="logo">
              <van-image
                class="logoIcon"
                :src="item.ProductPhoto && imgUrlFilter(item.ProductPhoto)"
              >
                <template v-slot:error>
                  <img class="ImgIcon" src="~assets/img/other/empty.gif" />
                </template>
              </van-image>
            </figure>
          </van-col>
          <van-col class="infoBoxRigth">
            <p class="productName">{{ item.ProductName }}</p>
            <p class="descriptions">{{ item.Descriptions }}</p>
            <p class="price">
              <span class="num">$</span>
              {{ item.MaxPrice }}
            </p>
          </van-col>
        </van-row>
        <!-- 转发等操作 -->
        <van-row type="flex" align="center" gutter="16" class="operationList">
          <van-col
            class="share"
            @click="
              shareGoods(
                item.ProductNumber,
                item.ProductName,
                item.MinPrice,
                item.ProductPhotoList[0]
              )
            "
          >
            <span>{{ $t.forward }}</span>
            <van-icon name="arrow" size="18" />
          </van-col>
        </van-row>
      </li>
    </ul>
  </div>
</template>
<script>
import Vue from "vue";
import { Image, Icon } from "vant";
import { Row, Col } from "vant";
Vue.use(Row).use(Col);
Vue.use(Image);
Vue.use(Icon);

export default {
  name: "ShareList",
  props: ["listProduct"],
  components: {},
  data() {
    return {};
  },
  methods: {
    imgUrlFilter(value) {
      return this.$api.formatImageUrl(value.split("|")[0]);
    },
    //转发方法
    shareGoods(id, name, price, image) {
      let auth = JSON.parse(localStorage.getItem("auth"));
      this.$commonEnv.commonAction(this.$commonEnv.actionType.shareGoods, {
        id: id,
        name: name,
        price: price,
        image: image,
        userId: auth.userId,
        shopId: auth.shopId,
        shareUrl:
          "https://shop.vancheen.com/web/details?ProductNumber=" +
          auth.shopId +
          id +
          "&ShopId=" +
          auth.shopId +
          "&BackHome=true",
        type: 1
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin spanNowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin puiblicFlex {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
}
@mixin publicBt {
  cursor: pointer;
  -webkit-cursor: pointer;
  border-radius: 32px;
  padding: 12px 0;
  text-align: center;
}
.ShareList {
  width: 100%;
  margin-top: 3%;
  .listBox {
    margin: 0% 3%;
    // padding: 36px;
    // box-sizing: border-box;
    // -webkit-box-sizing: border-box;
    .item {
      padding: 15px;
      border-radius: 20px;
      box-shadow: 0 0 0px 1px #ece7e7;
      background-color: #ffffff;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      margin-top: 3%;
      .itemTop {
        .itemTopTitle {
          width: 77%;
          font-size: 24px;
          flex: 1;
          -webkit-flex: 1;
          color: #000000;
          font-weight: bold;
        }
        .shelves {
          border: 1px solid #999999;
          width: 110px;
          text-align: center;
          border-radius: 32px;
          padding: 12px 0;
          color: #999999;
        }
        .putaway {
          border: 1px solid #ff625c;
          width: 110px;
          text-align: center;
          border-radius: 32px;
          padding: 15px 0;
          color: #ff625c;
        }
      }
    }
  }
  .infoBox {
    margin-top: 16px;
    .infoBoxLeft {
      width: 30%;
      .logo {
        margin: 0;
        padding: 0;
        position: relative;
        @include puiblicFlex;
        .logoIcon {
          .ImgIcon {
            width: 100px;
            height: 100px;
            object-fit: contain;
          }
          ::v-deep .van-image__img {
            width: 100px;
            height: 100px;
            object-fit: contain;
          }
          ::v-deep .van-image__error {
            width: 100px;
            height: 100px;
            object-fit: contain;
          }
        }
        .stickLogo {
          position: absolute;
          width: 100px;
          top: 0;
          left: 0;
        }
      }
    }
    .infoBoxRigth {
      width: 67%;
      margin-left: 3%;
      font-size: 24px;
      .infoBoxRigthTitle {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        width: 100%;
        margin: 0;
        padding: 0;
      }
      .productName {
        margin: 15px 0;
        padding: 0;
        max-width: 100%;
        font-size: 14px;
        color: #999999;
        @include spanNowrap;
      }
      .descriptions {
        margin: 15px 0;
        padding: 0;
        max-width: 100%;
        font-size: 14px;
        @include spanNowrap;
      }
      .price {
        margin: 15px 0;
        width: 100%;
        color: #ff625c;
        font-size: 16px;
        font-weight: bold;
        @include puiblicFlex;
        .priceName {
          max-width: 60%;
          @include spanNowrap;
        }
        .num {
          font-weight: bold;
          max-width: 40%;
          font-size: 10px;
          margin-right: 3px;
          @include spanNowrap;
        }
      }
    }
  }
  .operationList {
    margin-top: 26px;
    flex-direction: row-reverse;
    -webkit-flex-direction: row-reverse;
    span {
      vertical-align: middle;
      line-height: 18px;
    }
    .share {
      color: #25b9ff;
      @include publicBt;
      min-width: 70px;
      font-size: 16px;
      .van-icon {
        margin-left: 5px;
        vertical-align: middle;
      }
    }
  }
}
</style>
